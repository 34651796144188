import styles from './About.module.scss';
import icon from '../../assets/main/menu_icon01.png';
import faqData from '../../assets/data/faq.json';
import FaqFrame from '../../components/FaqFrame/FaqFrame';
import Header from '../../components/Header/Header';
import step01 from '../../assets/about/step01.png';
import step02 from '../../assets/about/step02.png';
import step03 from '../../assets/about/step03.png';
import step04 from '../../assets/about/step04.png';
import { useQuery } from 'react-query';
import { IProposalProductType } from '../Main/Main';
import axiosClient from '../../libs/axiosClient';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

function About() {
  const { productId } = useParams();
  const { data: proposalData } = useQuery<IProposalProductType[]>(
    'proposalData',
    () => axiosClient.get('/api/proposalList').then((res) => res.data),
    { staleTime: 60000 * 60 * 24 },
  );
  const [product, setProduct] = useState<IProposalProductType>();
  useEffect(() => {
    //캐시된 proposalData에서 파라미터로 넘겨받은 상품 id와 동일한 상품만 추출
    if (!proposalData || !productId) return;
    for (let i = 0; i < proposalData.length; i++) {
      if (String(proposalData[i].product_id) == productId)
        setProduct(proposalData[i]);
    }
  }, [proposalData]);
  const { t } = useTranslation();
  return (
    <>
      <Header />
      <div className={styles.container}>
        <article className={styles.bannerWrap}>
          <div className={styles.textWrap}>
            <h1>Online Doctor</h1>
            <h6>{t('about.1', '')}</h6>
            {/* <p>
              온닥은 비대면 진료를 통해
              <br />
              편리한 의료 서비스를 제공합니다.
            </p> */}
          </div>
        </article>
        <article className={styles.ondocInfomation}>
          <h3 dangerouslySetInnerHTML={{ __html: `${t('about.2', '')}` }}></h3>
          <p dangerouslySetInnerHTML={{ __html: `${t('about.3', '')}` }}></p>
        </article>
        <article className={styles.procedure}>
          <h3 className={styles.title}>📌 {t('about.4', '')}</h3>
          <div className={styles.contentWrap}>
            <ul>
              <li>
                <div className={styles.iconWrap}>
                  <img src={step01} />
                </div>
                <div className={styles.right}>
                  <span>step.01</span>
                  <div>
                    <span>{t('about.5', '')}</span>
                    <span>{t('about.6', '')}</span>
                  </div>
                </div>
              </li>
              <li>
                <div className={styles.iconWrap}>
                  <img src={step02} />
                </div>
                <div className={styles.right}>
                  <span>step.02</span>
                  <div>
                    <span>{t('about.7', '')}</span>
                    <span>{t('about.8', '')}</span>
                  </div>
                </div>
              </li>
              <li>
                <div className={styles.iconWrap}>
                  <img src={step03} />
                </div>
                <div className={styles.right}>
                  <span>step.03</span>
                  <div>
                    <span>{t('about.9', '')}</span>
                    <span>{t('about.10', '')}</span>
                  </div>
                </div>
              </li>
              <li>
                <div className={styles.iconWrap}>
                  <img src={step04} />
                </div>
                <div className={styles.right}>
                  <span>step.04</span>
                  <div>
                    <span>{t('about.11', '')}</span>
                    <span>{t('about.12', '')}</span>
                  </div>
                </div>
              </li>
            </ul>
            <div className={styles.detailWrap}>
              <div className={styles.iconWrap}>
                <img src={icon} />
              </div>
              <p>{t('about.13', '')}</p>
            </div>
          </div>
        </article>
        {/* <article className={styles.orderGuide}>
          <div className={styles.contentWrap}>
            <div className={styles.top}>
              <div className={styles.iconWrap}>
                <img src={step04} />
              </div>
              <div className={styles.right}>
                <span>주문 및 배송</span>
                <p>오후 2시 진료 마감건 당일 출고</p>
              </div>
            </div>
            <div className={styles.detailWrap}>
              <div className={styles.iconWrap}>
                <img src={icon} />
              </div>
              <p>
                온닥은 풀필먼트 물류 시스템을 적용하여 당일 진료 마감건에 한해
                수도권 (서울, 경기) 지역은 새벽배송이 될 수 있도록 시스템을
                구축하고 있습니다.
              </p>
            </div>
          </div>
        </article> */}
        {/* <article className={styles.faqWrap}>
          <h3 className={styles.title}>🔎 자주 묻는 질문</h3>
          <ul>
            {product &&
              product.product.product_faq
                .filter((faq, idx) => idx < 3)
                .map((faq, idx) => (
                  <li key={faq.id}>
                    <FaqFrame faq={faq} />
                  </li>
                ))}
          </ul>
        </article> */}
      </div>
    </>
  );
}
export default About;
