import axiosClient from '../libs/axiosClient';
export interface ITokenInfoType {
  email: string;
  exp: number;
  id: number;
  name: string;
  nickname: string;
  phone: string;
  profile: string;
  role: number;
  user_type: string;
  member_id: string;
}
export function checkLoginStatus() {
  const token = localStorage.getItem('token');
  if (!token) return false;
  else return true;
}
export const STORAGE_ITEMS = {
  TOKEN: 'token',
  TOKENEXPIRE: 'token-expire',
  RTOKEN: 'refresh-token',
  RTOKENEXPIRE: 'refresh-token-expire',
};
interface ITokenPropsType {
  access_token: string;
}
export function setToken(props: ITokenPropsType) {
  localStorage.setItem(STORAGE_ITEMS.TOKEN, props.access_token);
  axiosClient.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    STORAGE_ITEMS.TOKEN,
  )}`;
}
export function logout() {
  localStorage.removeItem(STORAGE_ITEMS.TOKEN);
  delete axiosClient.defaults.headers.common.Authorization;
}
