import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './Login.module.scss';
import Draggable from 'react-draggable';
// import { useDraggable } from 'use-draggable';
import arrow01 from '../../assets/arrow01.png';
import loadingArrow01 from '../../assets/Login/loading_arrow01.png';
import loadingArrow02 from '../../assets/Login/loading_arrow02.png';
import loadingArrow03 from '../../assets/Login/loading_arrow03.png';
import axiosClient from '../../libs/axiosClient';
import { setToken } from '../../auth/auth';
import { useTranslation } from 'react-i18next';

let minSubmitX = 110;
let maxSlideX = 130;
interface IWelcomeType {
  welcome_image: null | string;
  welcome_message: null | string;
  welcome_message2: null | string;
  country: string;
}
function Login() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [code, setCode] = useState('');
  const [arrows, setArrows] = useState([
    loadingArrow01,
    loadingArrow02,
    loadingArrow03,
  ]);
  const nodeRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState({ x: 10, y: 0 });
  useEffect(() => {
    setInterval(() => {
      setArrows((prev) => [prev[2], prev[0], prev[1]]);
    }, 700);
  }, []);
  const handleSubmit = async (codeNumber?: string) => {
    setPosition({ x: maxSlideX, y: 0 });
    await axiosClient
      .post('/api/codeCheck', {
        id: id,
        code: codeNumber ? codeNumber : code,
      })
      .then((res) => {
        if (res.data.error) {
          alert(t('login.1'));
        } else if (res.data.token) {
          setToken({ access_token: res.data.token });
          navigate('/main');
        }
      })
      .catch((err) => alert(t('login.2')));
    setPosition({ x: 10, y: 0 });
  };
  const [welcome, setWelcome] = useState<IWelcomeType>();
  useEffect(() => {
    axiosClient
      .get(`/api/welcomeMessage/${id}`)
      .then((res) => {
        if (res.data == '') {
          alert('유효하지 않은 셀러 아이디입니다. 관리자에게 문의해주세요.');
          return;
        }
        i18n.changeLanguage(res.data.country);
        setWelcome(res.data);
      })
      .catch((err) => alert('에러가 발생했습니다. 관리자에게 문의해주세요.'));
  }, []);
  useEffect(() => {
    if (!welcome) return;
    let params = new URL(document.URL).searchParams;
    let code = params.get('code');
    if (!code) return;
    handleSubmit(code);
  }, [welcome]);
  const { t, i18n } = useTranslation();
  return (
    <>
      <div className={styles.container}>
        <div className={styles.welcome}>
          <span
            dangerouslySetInnerHTML={{ __html: welcome?.welcome_message || '' }}
          ></span>
        </div>
        <div className={styles.title}>
          <h3
            dangerouslySetInnerHTML={{
              __html: welcome?.welcome_message2 || '',
            }}
          ></h3>
        </div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <div className={styles.inputWrap}>
            <input
              type="text"
              value={code}
              onChange={(e) => setCode(e.currentTarget.value)}
              placeholder="code number"
              style={{ borderBottom: code == '' ? 'none' : '1px solid white' }}
            />
          </div>
          <div className={styles.slideToUnlock}>
            <Draggable
              cancel={''}
              onDrag={(e, data) => {
                setPosition({ x: data.x, y: 0 });
              }}
              onStart={() => setIsDragging(true)}
              onStop={(e, data) => {
                if (data.x > minSubmitX) handleSubmit();
                else setPosition({ x: 10, y: 0 });
                setIsDragging(false);
              }}
              bounds={'parent'}
              axis="x"
              position={position}
            >
              <div
                className={styles.circle}
                ref={nodeRef}
                style={{
                  transition: !isDragging ? 'all .5s' : 'unset',
                  background: `rgba(237, 238, 240, ${
                    0.2 + 0.8 * (position.x / maxSlideX)
                  })`,
                }}
              >
                <img src={arrow01} />
              </div>
            </Draggable>
            <div className={styles.arrows}>
              {arrows.map((arrow) => (
                <img src={arrow} key={arrow} />
              ))}
            </div>
            <div className={styles.login}>login</div>
            <div
              className={styles.shadow}
              style={{
                opacity: position.x / maxSlideX,
                transition: !isDragging ? 'all .5s' : 'unset',
              }}
            ></div>
          </div>
        </form>
      </div>
    </>
  );
}
export default Login;
