import styles from './ProductDetailModal.module.scss';
import close from '../../assets/close.png';
import { useEffect } from 'react';
import { IProposalProductType } from '../../pages/Main/Main';
interface IPropsType {
  selectedProduct: IProposalProductType;
  setSelectedProduct: React.Dispatch<
    React.SetStateAction<IProposalProductType | undefined>
  >;
}

function ProductDetailmodal(props: IPropsType) {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);
  return (
    <>
      <div
        className={styles.closeBtn}
        onClick={() => props.setSelectedProduct(undefined)}
      >
        <img src={close} alt="닫기" />
      </div>
      <div className={styles.container}>
        <article className={styles.modalWrap}>
          <div className={styles.contentArea}>
            <div className={styles.productThumbnail}>
              <img
                src={
                  process.env.REACT_APP_API_URL +
                  '/storage/' +
                  props.selectedProduct.product.product_image
                }
                alt={props.selectedProduct.product.product_name}
              />
            </div>
            {/* <ul className={styles.iconList}>
              <li className="New">New</li>
              <li className="Event">Event</li>
            </ul> */}
            <div className={styles.productInfoWrap}>
              <h1
                dangerouslySetInnerHTML={{
                  __html: props.selectedProduct.product.product_name,
                }}
              ></h1>
              {/* <h3>
                {props.selectedProduct.product.discount_price.toLocaleString()}
              </h3> */}
            </div>
            <div className={styles.productExplanation}>
              <p
                dangerouslySetInnerHTML={{
                  __html: props.selectedProduct.product.summary || '',
                }}
              ></p>
            </div>
            <div
              className={styles.productDetail}
              dangerouslySetInnerHTML={{
                __html: props.selectedProduct.product.description,
              }}
            ></div>
          </div>
        </article>
      </div>
    </>
  );
}
export default ProductDetailmodal;
