import Header from '../../components/Header/Header';
import styles from './Commission.module.scss';
import arrow from '../../assets/header/back_arrow.png';
import warning from '../../assets/warning.png';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { IProposalProductType } from '../Main/Main';
import axiosClient from '../../libs/axiosClient';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

function Commission() {
  const { productId } = useParams();
  const { data: proposalData } = useQuery<IProposalProductType[]>(
    'proposalData',
    () => axiosClient.get('/api/proposalList').then((res) => res.data),
    { staleTime: 60000 * 60 * 24 },
  );
  const [product, setProduct] = useState<IProposalProductType>();
  useEffect(() => {
    //캐시된 proposalData에서 파라미터로 넘겨받은 상품 id와 동일한 상품만 추출
    if (!proposalData || !productId) return;
    for (let i = 0; i < proposalData.length; i++) {
      if (String(proposalData[i].product_id) == productId)
        setProduct(proposalData[i]);
    }
  }, [proposalData]);
  const { t } = useTranslation();
  return (
    <>
      <Header />
      <div className={styles.container}>
        <h1>Online Doctor</h1>
        <h6>{t('commission.1', '')}</h6>
        <span className={styles.subTitle}>🏷 {t('commission.2', '')}</span>
        <ul className={styles.priceInfoList}>
          {product?.product.product_option.map((option) => (
            <li>
              <div className={styles.originalPriceInfoWrap}>
                <span>{t('commission.3', '')}</span>
                <span>{option.price}</span>
              </div>
              <img src={arrow} />
              <div className={styles.discountedPriceInfoWrap}>
                <span>{t('commission.4', '')}</span>
                <span>{option.discount_price}</span>
              </div>
            </li>
          ))}
          {/* <li>
            <div className={styles.originalPriceInfoWrap}>
              <span>판매가</span>
              <span>458,000원 (60포)</span>
            </div>
            <img src={arrow} />
            <div className={styles.discountedPriceInfoWrap}>
              <span>할인가</span>
              <span>278,000원 (60포)</span>
            </div>
          </li>
          <li>
            <div className={styles.originalPriceInfoWrap}>
              <span>판매가</span>
              <span>458,000원 (60포)</span>
            </div>
            <img src={arrow} />
            <div className={styles.discountedPriceInfoWrap}>
              <span>할인가</span>
              <span>278,000원 (60포)</span>
            </div>
          </li> */}
        </ul>
        <div className={styles.chargeInfoWrap}>
          <h3>{t('commission.5', '')}</h3>
          <span>{product?.commission}%</span>
        </div>
        {product?.product.is_service_intro == 'Y' && (
          <div className={styles.warningWrap}>
            <div className={styles.iconWrap}>
              <img src={warning} />
            </div>
            <p>{t('commission.6', '')}</p>
          </div>
        )}
      </div>
    </>
  );
}
export default Commission;
