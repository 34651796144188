import Header from '../../components/Header/Header';
import styles from './Faq.module.scss';
import faqData from '../../assets/data/faq.json';
import FaqFrame from '../../components/FaqFrame/FaqFrame';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { IProposalProductType } from '../Main/Main';
import axiosClient from '../../libs/axiosClient';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

function Faq() {
  const { productId } = useParams();
  const { data: proposalData } = useQuery<IProposalProductType[]>(
    'proposalData',
    () => axiosClient.get('/api/proposalList').then((res) => res.data),
    { staleTime: 60000 * 60 * 24 },
  );
  const [product, setProduct] = useState<IProposalProductType>();
  useEffect(() => {
    //캐시된 proposalData에서 파라미터로 넘겨받은 상품 id와 동일한 상품만 추출
    if (!proposalData || !productId) return;
    for (let i = 0; i < proposalData.length; i++) {
      if (String(proposalData[i].product_id) == productId)
        setProduct(proposalData[i]);
    }
  }, [proposalData]);
  const { t } = useTranslation();
  return (
    <>
      <Header />
      <div className={styles.container}>
        <h1>Online Doctor</h1>
        <h6>{t('faq.1', '')}</h6>
        <ul className={styles.faqList}>
          {product &&
            product.product.product_faq.map((faq, idx) => (
              <li key={faq.id}>
                <FaqFrame faq={faq} />
              </li>
            ))}
        </ul>
      </div>
    </>
  );
}
export default Faq;
