import axios from 'axios';
import { checkLoginStatus, logout } from '../auth/auth';
const axiosClient = axios.create({
  // baseURL: '',
  baseURL: process.env.REACT_APP_API_URL,
});
axiosClient.interceptors.request.use(
  function (config) {
    if (checkLoginStatus())
      config.headers!.Authorization = `Bearer ${localStorage.getItem('token')}`;
    return config;
  },
  function (error) {
    // 요청 오류가 있는 작업 수행
    return Promise.reject(error);
  },
);

axiosClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (!error.response && !error.response.status) {
      return Promise.reject(error); // 네트워크 에러로 인해 response 없을 시 단순 에러 반환
    }
    const {
      config,
      response: { status },
    } = error;
    if (status === 401) {
      alert('세션이 만료되었습니다.');
      window.location.href = '/';
    }
    if (status === 403) {
      logout();
      window.location.href = '/';
    }
    return Promise.reject(error);
  },
);

export default axiosClient;
