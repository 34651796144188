import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import main from './main.json';
import about from './about.json';
import faq from './faq.json';
import commission from './commission.json';
import login from './login.json';

i18n.use(initReactI18next).init({
  resources: {
    jp: {
      translation: {
        main: { ...main.jp },
        about: { ...about.jp },
        faq: { ...faq.jp },
        commission: { ...commission.jp },
        login: { ...login.jp },
      },
    },
    kr: {
      translation: {
        main: { ...main.ko },
        about: { ...about.ko },
        faq: { ...faq.ko },
        commission: { ...commission.ko },
        login: { ...login.ko },
      },
    },
  },
  lng: '',
});
export default i18n;
