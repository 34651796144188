import { useState } from 'react';
import styles from './FaqFrame.module.scss';
interface IFaqType {
  content: string;
  id: number;
  product_id: number;
  title: string;
}
function FaqFrame(props: { faq: IFaqType }) {
  const [answerOpen, setAnswerOpen] = useState(false);
  return (
    <div className={`${styles.container} ${answerOpen ? styles.isOpen : ''}`}>
      <div
        className={styles.ask}
        onClick={() => setAnswerOpen((prev) => !prev)}
      >
        <div className={`${styles.light} ${answerOpen ? styles.on : ''}`}></div>
        <p dangerouslySetInnerHTML={{ __html: props.faq.title }}></p>
      </div>
      <div
        className={`${styles.answerWrap} ${answerOpen ? styles.isOpen : ''}`}
      >
        <p
          className={`${styles.answer} ${answerOpen ? styles.isOpen : ''}`}
          dangerouslySetInnerHTML={{ __html: props.faq.content }}
        ></p>
      </div>
    </div>
  );
}
export default FaqFrame;
