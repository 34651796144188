import styles from './Main.module.scss';
import SwiperCore from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { EffectCoverflow } from 'swiper';
import defaultProfile from '../../assets/default_profile.png';
import banner from '../../assets/main/banner.png';
import arrow from '../../assets/arrow02.png';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ProductDetailmodal from '../../components/ProductDetailModal/ProductDetailModal';
import axiosClient from '../../libs/axiosClient';
import { useQuery } from 'react-query';
import menuIcon01 from '../../assets/main/menu_icon01.png';
import menuIcon02 from '../../assets/main/menu_icon02.png';
import menuIcon04 from '../../assets/main/menu_icon04.png';
import menuIcon05 from '../../assets/main/menu_icon05.png';
import { useTranslation } from 'react-i18next';

export interface IUserInfoType {
  avatar: null | string;
  department_id: null | number;
  email: string;
  gender: null | string;
  id: number;
  name: string;
  nickname: string;
  welcome_image: null | string;
  welcome_message: null | string;
  welcome_message2: null | string;
  country: string;
}
export interface IProposalProductType {
  commission: number;
  product: {
    description: string;
    discount_price: number;
    price: number;
    is_service_intro: string;
    product_faq: {
      content: string;
      id: number;
      product_id: number;
      title: string;
    }[];
    product_image: string;
    product_name: string;
    product_option: {
      discount_price: string;
      price: string;
      sort_order: number;
    }[];
    product_post: {
      content: string;
      created_at: string;
      id: number;
      product_id: number;
      sort_order: null | number;
      title: string;
      updated_at: string;
    }[];
    summary: string | null;
  };
  product_id: number;
}

function Main() {
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();

  const { data: userData } = useQuery<IUserInfoType>(
    'userData',
    () =>
      axiosClient.get('/api/user').then((res) => {
        i18n.changeLanguage(res.data.country);
        return res.data;
      }),
    { staleTime: 60000 * 60 * 24 },
  );
  const { data: proposalData } = useQuery<IProposalProductType[]>(
    'proposalData',
    () => axiosClient.get('/api/proposalList').then((res) => res.data),
    { staleTime: 60000 * 60 * 24 },
  );
  const [selectedProduct, setSelectedProduct] = useState<
    IProposalProductType | undefined
  >(); //상품 슬라이드에서 선택된 상품의 정보가 담김 해당 상태에 값이 있을 시 상품상세 모달에 props로 해당 상태 넘기고 띄움
  useEffect(() => {
    if (!selectedProduct && location.pathname != '/main') navigate('/main');
    else if (selectedProduct) navigate('/main/open');
  }, [selectedProduct]);
  useEffect(() => {
    if (location.pathname == '/main') setSelectedProduct(undefined);
  }, [location]);

  const [imageSwiper, setImageSwiper] = useState<SwiperCore>();
  const [nameSwiper, setNameSwiper] = useState<SwiperCore>();
  const [menuSwiper, setMenuSwiper] = useState<SwiperCore>();
  const [activeIdx, setActiveIdx] = useState(0);
  const activeIdxRef = useRef<number>();
  const handlePrevSwipe = () => {
    imageSwiper?.slidePrev();
    nameSwiper?.slidePrev();
    menuSwiper?.slidePrev();
  };
  const handleNextSwipe = () => {
    imageSwiper?.slideNext();
    nameSwiper?.slideNext();
    menuSwiper?.slideNext();
  };
  useEffect(() => {
    sessionStorage.removeItem('slideIndex');
    return () => {
      if (activeIdxRef.current)
        sessionStorage.setItem('slideIndex', String(activeIdxRef.current));
    };
  }, []);
  console.log(t('kr.main.2'));
  return (
    <>
      <div className={styles.container}>
        <header>
          <div className={styles.profileWrap}>
            <div className={styles.profileImage}>
              {userData?.avatar ? (
                <img
                  src={
                    process.env.REACT_APP_API_URL +
                    '/storage/' +
                    userData.avatar
                  }
                />
              ) : (
                <img src={defaultProfile} className={styles.defaultImage} />
              )}
            </div>
            <span>
              {userData?.nickname}
              {t('main.1', '')}
            </span>
          </div>
        </header>
        {/* {userData?.welcome_image && (
          <article className={styles.bannerWrap}>
            <img
              src={
                process.env.REACT_APP_API_URL +
                '/storage/' +
                userData.welcome_image
              }
              alt="배너"
            />
          </article>
        )} */}
        <article className={styles.productSlideWrap}>
          {proposalData && (
            <Swiper
              initialSlide={Number(sessionStorage.getItem('slideIndex'))}
              slidesPerView={'auto'}
              centeredSlides
              spaceBetween={2.6}
              onSwiper={setImageSwiper}
              modules={[EffectCoverflow]}
              effect="coverflow"
              onSlideChangeTransitionStart={(swiper) => {
                setActiveIdx(swiper.activeIndex);
                activeIdxRef.current = swiper.activeIndex;
              }}
              loop={proposalData.length >= 3 ? true : false}
              coverflowEffect={{
                depth: 400,
                rotate: 0,
                slideShadows: false,
                modifier: 1.5,
                stretch: -40,
              }}
              touchRatio={0}
            >
              {proposalData?.map((product) => (
                <SwiperSlide
                  key={product.product_id}
                  className={styles.thumbnailSlide}
                  onClick={() => setSelectedProduct(product)}
                >
                  <div className={styles.productThumbnailWrap}>
                    <img
                      src={
                        process.env.REACT_APP_API_URL +
                        '/storage/' +
                        product.product.product_image
                      }
                      alt="상품썸네일"
                    />
                  </div>
                </SwiperSlide>
              ))}
              {proposalData.length == 3 && //슬라이드에 한 번에 노출되는 개수 3개와 상품 개수 3개 동일 시 슬라이드 작동 관련 이슈 있어서 상품 3개일 때만 한 번 더 추가 렌더링
                proposalData?.map((product) => (
                  <SwiperSlide
                    key={product.product_id * -1}
                    className={styles.thumbnailSlide}
                    onClick={() => setSelectedProduct(product)}
                  >
                    <div className={styles.productThumbnailWrap}>
                      <img
                        src={
                          process.env.REACT_APP_API_URL +
                          '/storage/' +
                          product.product.product_image
                        }
                        alt="상품썸네일"
                      />
                    </div>
                  </SwiperSlide>
                ))}
            </Swiper>
          )}
          <div className={styles.productNameSliderWrap}>
            {proposalData &&
              proposalData.length > 1 &&
              (activeIdx > 0 || proposalData.length >= 3) && ( // 제안 상품 갯수 3개부턴 무한 슬라이드 형식이기 때문에 activeIdx와 무관하게 버튼 노출
                <div className={styles.slideBtn} onClick={handlePrevSwipe}>
                  <img src={arrow} />
                </div>
              )}
            {proposalData && (
              <Swiper
                onSwiper={setNameSwiper}
                touchRatio={0}
                initialSlide={Number(sessionStorage.getItem('slideIndex'))}
                loop={proposalData.length >= 3 ? true : false}
              >
                {proposalData?.map((product, idx) => (
                  <SwiperSlide
                    key={product.product_id}
                    className={styles.productNameSlide}
                  >
                    <div
                      className={styles.productInfoWrap}
                      onClick={() => setSelectedProduct(product)}
                    >
                      {/* <ul className={styles.iconList}>
                        <li className="New">New</li>
                        <li className="Event">Event</li>
                      </ul> */}
                      <h3
                        dangerouslySetInnerHTML={{
                          __html: product.product.product_name,
                        }}
                      ></h3>
                      {/* <span>
                        {product.product.discount_price.toLocaleString()}
                      </span> */}
                    </div>
                  </SwiperSlide>
                ))}
                {proposalData.length == 3 && //슬라이드에 한 번에 노출되는 개수 3개와 상품 개수 3개 동일 시 슬라이드 작동 관련 이슈 있어서 상품 3개일 때만 한 번 더 추가 렌더링
                  proposalData?.map((product, idx) => (
                    <SwiperSlide
                      key={product.product_id}
                      className={styles.productNameSlide}
                    >
                      <div
                        className={styles.productInfoWrap}
                        onClick={() => setSelectedProduct(product)}
                      >
                        {/* <ul className={styles.iconList}>
                                      <li className="New">New</li>
                                      <li className="Event">Event</li>
                                    </ul> */}
                        <h3
                          dangerouslySetInnerHTML={{
                            __html: product.product.product_name,
                          }}
                        ></h3>
                        {/* <span>
                                      {product.product.discount_price.toLocaleString()}
                                    </span> */}
                      </div>
                    </SwiperSlide>
                  ))}
              </Swiper>
            )}
            {proposalData &&
              proposalData.length > 1 &&
              (activeIdx < proposalData.length - 1 ||
                proposalData.length >= 3) && (
                <div className={styles.slideBtn} onClick={handleNextSwipe}>
                  <img src={arrow} />
                </div>
              )}
          </div>
          {proposalData && (
            <Swiper
              slidesPerView={'auto'}
              centeredSlides
              spaceBetween={16}
              className={styles.productMenuSlideWrap}
              onSwiper={setMenuSwiper}
              touchRatio={0}
              initialSlide={Number(sessionStorage.getItem('slideIndex'))}
              loop={proposalData.length >= 3 ? true : false}
            >
              {proposalData?.map((product) => (
                <SwiperSlide
                  className={styles.productMenuSlide}
                  key={product.product_id}
                >
                  <ul>
                    <li onClick={() => setSelectedProduct(product)}>
                      <div className={styles.left}>
                        <img src={menuIcon05} />
                        <div className={styles.menuNameWrap}>
                          <span>제품보기 </span>
                          {i18n.language !== 'kr' && (
                            <span>({t('main.2', '')})</span>
                          )}
                        </div>
                      </div>
                      <img src={arrow} />
                    </li>
                    {product.product.is_service_intro == 'Y' && (
                      <li
                        onClick={() => navigate(`/about/${product.product_id}`)}
                      >
                        <div className={styles.left}>
                          <img src={menuIcon01} />
                          <div className={styles.menuNameWrap}>
                            <span>서비스 소개 </span>
                            {i18n.language !== 'kr' && (
                              <span>({t('main.3', '')})</span>
                            )}
                          </div>
                        </div>
                        <img src={arrow} />
                      </li>
                    )}
                    <li onClick={() => navigate(`/faq/${product.product_id}`)}>
                      <div className={styles.left}>
                        <img src={menuIcon02} />
                        <div className={styles.menuNameWrap}>
                          <span>자주묻는질문 </span>
                          {i18n.language !== 'kr' && (
                            <span>({t('main.4', '')})</span>
                          )}
                        </div>
                      </div>
                      <img src={arrow} />
                    </li>
                    {product.product.product_post.map((post, idx) => (
                      <li
                        key={post.id}
                        onClick={() =>
                          navigate(`/difference/${product.product_id}/${idx}`)
                        }
                      >
                        <div className={styles.left}>
                          <span className={styles.icon}>
                            {post.title.slice(0, 2)}
                          </span>
                          <div className={styles.left}>
                            <div className={styles.menuNameWrap}>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: post.title.slice(2),
                                }}
                              ></span>
                            </div>
                          </div>
                        </div>
                        <img src={arrow} />
                      </li>
                    ))}
                    <li
                      onClick={() =>
                        navigate(`/commission/${product.product_id}`)
                      }
                    >
                      <div className={styles.left}>
                        <img src={menuIcon04} />
                        <div className={styles.menuNameWrap}>
                          <span>판매가 및 셀러 수수료 </span>
                          {i18n.language !== 'kr' && (
                            <span>({t('main.5', '')})</span>
                          )}
                        </div>
                      </div>
                      <img src={arrow} />
                    </li>
                  </ul>
                </SwiperSlide>
              ))}
              {proposalData.length == 3 && //슬라이드에 한 번에 노출되는 개수 3개와 상품 개수 3개 동일 시 슬라이드 작동 관련 이슈 있어서 상품 3개일 때만 한 번 더 추가 렌더링
                proposalData?.map((product) => (
                  <SwiperSlide
                    className={styles.productMenuSlide}
                    key={product.product_id * -1}
                  >
                    <ul>
                      <li onClick={() => setSelectedProduct(product)}>
                        <div className={styles.left}>
                          <img src={menuIcon05} />
                          <div className={styles.menuNameWrap}>
                            <span>제품보기 </span>
                            {i18n.language !== 'kr' && (
                              <span>({t('main.2', '')})</span>
                            )}
                          </div>
                        </div>
                        <img src={arrow} />
                      </li>
                      {product.product.is_service_intro == 'Y' && (
                        <li
                          onClick={() =>
                            navigate(`/about/${product.product_id}`)
                          }
                        >
                          <div className={styles.left}>
                            <img src={menuIcon01} />
                            <div className={styles.menuNameWrap}>
                              <span>서비스 소개 </span>
                              {i18n.language !== 'kr' && (
                                <span>({t('main.3', '')})</span>
                              )}
                            </div>
                          </div>
                          <img src={arrow} />
                        </li>
                      )}
                      <li
                        onClick={() => navigate(`/faq/${product.product_id}`)}
                      >
                        <div className={styles.left}>
                          <img src={menuIcon02} />
                          <div className={styles.menuNameWrap}>
                            <span>자주묻는질문 </span>
                            {i18n.language !== 'kr' && (
                              <span>({t('main.4', '')})</span>
                            )}
                          </div>
                        </div>
                        <img src={arrow} />
                      </li>
                      {product.product.product_post.map((post, idx) => (
                        <li
                          key={post.id}
                          onClick={() =>
                            navigate(`/difference/${product.product_id}/${idx}`)
                          }
                        >
                          <div className={styles.left}>
                            <span className={styles.icon}>
                              {post.title.slice(0, 2)}
                            </span>
                            <div className={styles.left}>
                              <div className={styles.menuNameWrap}>
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: post.title.slice(2),
                                  }}
                                ></span>
                              </div>
                            </div>
                          </div>
                          <img src={arrow} />
                        </li>
                      ))}
                      <li
                        onClick={() =>
                          navigate(`/commission/${product.product_id}`)
                        }
                      >
                        <div className={styles.left}>
                          <img src={menuIcon04} />
                          <div className={styles.menuNameWrap}>
                            <span>판매가 및 셀러 수수료 </span>
                            {i18n.language !== 'kr' && (
                              <span>({t('main.5', '')})</span>
                            )}
                          </div>
                        </div>
                        <img src={arrow} />
                      </li>
                    </ul>
                  </SwiperSlide>
                ))}
            </Swiper>
          )}
        </article>
      </div>
      {selectedProduct && (
        <ProductDetailmodal
          selectedProduct={selectedProduct}
          setSelectedProduct={setSelectedProduct}
        />
      )}
    </>
  );
}
export default Main;
