import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './pages/Login/Login';
import NotFound from './pages/NotFound/NotFound';
import Main from './pages/Main/Main';
import About from './pages/About/About';
import Faq from './pages/Faq/Faq';
import Difference from './pages/Difference/Difference';
import Commission from './pages/Commission/Commission';
import ScrollTop from './components/ScrollTop/ScrollTop';
import GetUserInfo from './components/GetUserInfo';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollTop />
        <GetUserInfo />
        <Routes>
          <Route path="/login/:id" element={<Login />} />
          <Route path="/main" element={<Main />} />
          <Route path="/main/:modalOpen" element={<Main />} />
          <Route path="/about/:productId" element={<About />} />
          <Route path="/faq/:productId" element={<Faq />} />
          <Route
            path="/difference/:productId/:index"
            element={<Difference />}
          />
          <Route path="/commission/:productId" element={<Commission />} />
          {/* <Route path="*" element={<NotFound />} /> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
