import { useQuery } from 'react-query';
import { IUserInfoType } from '../pages/Main/Main';
import axiosClient from '../libs/axiosClient';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
// 이 컴포넌트는 user data를 가져오는 메인 페이지 이외의 페이지에서 새로고침시 language에 필요한 country값을 가져오기 위해 만듦.
function GetUserInfo() {
  const { pathname: path } = useLocation();
  const { data: userData } = useQuery<IUserInfoType>(
    'userData',
    () =>
      axiosClient.get('/api/user').then((res) => {
        i18n.changeLanguage(res.data.country);
        console.log('fetched');
        return res.data;
      }),
    {
      staleTime: 60000 * 60 * 24,
      enabled:
        path.includes('commission') ||
        path.includes('about') ||
        path.includes('faq') ||
        path.includes('difference'),
    },
  );
  const { t, i18n } = useTranslation();

  return <></>;
}
export default GetUserInfo;
