import styles from './Header.module.scss';
import arrow from '../../assets/header/back_arrow02.png';
import { useNavigate } from 'react-router-dom';
function Header() {
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <div className={styles.contentWrap}>
        <div className={styles.backBtn} onClick={() => navigate(-1)}>
          <img src={arrow} alt="뒤로가기" />
        </div>
      </div>
    </div>
  );
}
export default Header;
