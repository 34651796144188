import Header from '../../components/Header/Header';
import styles from './Difference.module.scss';
import temporary01 from '../../assets/temporary/temporary01.png';
import temporary02 from '../../assets/temporary/temporary02.png';
import temporary03 from '../../assets/temporary/temporary03.png';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { IProposalProductType } from '../Main/Main';
import axiosClient from '../../libs/axiosClient';
import { useEffect, useState } from 'react';
function Difference() {
  const { productId, index } = useParams();
  const { data: proposalData } = useQuery<IProposalProductType[]>(
    'proposalData',
    () => axiosClient.get('/api/proposalList').then((res) => res.data),
    { staleTime: 60000 * 60 * 24 },
  );
  const [product, setProduct] = useState<IProposalProductType>();
  useEffect(() => {
    //캐시된 proposalData에서 파라미터로 넘겨받은 상품 id와 동일한 상품만 추출
    if (!proposalData || !productId) return;
    for (let i = 0; i < proposalData.length; i++) {
      if (String(proposalData[i].product_id) == productId)
        setProduct(proposalData[i]);
    }
  }, [proposalData]);

  return (
    <>
      <Header />
      <div className={styles.container}>
        <h1>Online Doctor</h1>
        <h6
          dangerouslySetInnerHTML={{
            __html: product?.product.product_post[Number(index)].title || '',
          }}
        ></h6>
        <div
          className={styles.contentWrap}
          dangerouslySetInnerHTML={{
            __html: product?.product.product_post[Number(index)].content || '',
          }}
        >
          {/* <div className="box">
            <div className="imageWrap">
              <img src={temporary01} />
            </div>
            <div className="contentText">
              <p>
                1. 보감비책은 의약품으로 일반 건강식품과는 비교도 안될정도로
                섭취시 즉각적으로 식욕억제 효과를 느낄 수 있습니다.
              </p>
              <br />
              <p>
                2. 보감비책은 동결건조 기술로 탕약 120ml의 한약 성분을
                고농축으로 압축하여 효능을 극대화 했습니다.
              </p>
              <br />
              <p>
                3. 대부분의 타사 다이어트 약은 먹기 불편한 환으로 만들어져 있어
                쓴 맛과 한약냄새 그리고 목넘김이 불편합니다. 보감비책 다이어트
                정은 알약 형태로 만들어져 언제 어디서나 간편하게 섭취할 수
                있습니다.
              </p>
            </div>
          </div>
          <div className="box">
            <div className="imageWrap">
              <img src={temporary02} />
            </div>
            <h3 className="title">타사 다이어트 환</h3>
            <div className="contentText">
              <p>· 먹기 불편한 환 크기</p>
              <br />
              <p>· 한약 특유의 냄새</p>
              <br />
              <p>· 쓴맛</p>
            </div>
          </div>
          <div className="box">
            <div className="imageWrap">
              <img src={temporary03} />
            </div>
            <h3 className="title">보감비책 다이어트 정</h3>
            <div className="contentText">
              <p>· 먹기 간편한 알약형태</p>
              <br />
              <p>· 동결건조 기술로 고농축 제조하여 빠른 흡수</p>
              <br />
              <p>· 한약 냄새와 쓴맛이 없음</p>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}
export default Difference;
